<template>
  <header class="header">
    <div class="header__top-wrapper">
      <div class="container">
        <div class="header__top">
          <div class="header__burger" @click="openMenu">
            <img src="@/assets/img/icons/header-burger.svg" alt="" />
          </div>
          <div class="header__logo">
            <locale-router-link to="">
              <img src="@/assets/img/icons/logo.svg" alt="" />
            </locale-router-link>
          </div>
          <div class="header__main">
            <div class="header__main-top">
              <form class="header__search">
                <input class="header__search-input" type="text" />
                <button class="header__search-btn">
                  <img
                    class="header__search-img"
                    src="@/assets/img/icons/search-icon.svg"
                    alt=""
                  />
                </button>
              </form>
              <div class="header__settings">
                <div
                  v-if="selectedCity"
                  class="header__address header__settings-item"
                  @click="openSelectCity"
                >
                  {{ selectedCity.attributes.name.substring(0, 3) }}
                </div>
                <lang-change class="header__settings-item" />
                <button
                  class="header__login header__settings-item"
                  @click="openIsAuthActive()"
                >
                  <img src="@/assets/img/icons/login-icon.svg" alt="" /> Войти
                </button>
              </div>
            </div>
            <nav class="header__main-nav">
              <ul class="header__main-nav--list">
                <li class="header__main-nav--item">
                  <locale-router-link
                    to="about"
                    class="header__main-nav--link header__main-nav--blue"
                    >Скидки</locale-router-link
                  >
                </li>
                <li class="header__main-nav--item">
                  <locale-router-link
                    to="stocks"
                    class="header__main-nav--link header__main-nav--red"
                    >Акции</locale-router-link
                  >
                </li>
                <li class="header__main-nav--item">
                  <locale-router-link
                    to="heating-systems"
                    class="header__main-nav--link"
                    >Системы нагревания</locale-router-link
                  >
                </li>
                <li class="header__main-nav--item">
                  <locale-router-link to="stocks" class="header__main-nav--link"
                    >Готовим коктейли</locale-router-link
                  >
                </li>
                <li class="header__main-nav--item">
                  <locale-router-link to="stocks" class="header__main-nav--link"
                    >Бренды</locale-router-link
                  >
                </li>
                <li class="header__main-nav--item">
                  <button @click="openMenu" class="header__main-nav--menu">
                    <img src="@/assets/img/header-menu/burger.svg" />Меню
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container"
      style="position: relative"
      @mouseleave="activeNavBar = 0"
    >
      <nav class="header__nav">
        <ul class="header__nav-list">
          <li
            @mouseenter="activeNavBar = first.id"
            class="header__nav-item"
            v-for="first in bottomNav"
            :key="first.id"
          >
            <!--  -->
            <locale-router-link to="stocks" class="header__nav-link">
              {{ first.name }}
              <div class="header__nav__hover" v-if="activeNavBar === first.id">
                <ul class="header__nav__hover-content">
                  <li
                    class="header__nav__hover-item"
                    v-for="second in first.list"
                    :key="second.id"
                  >
                    <h1>{{ second.name }}</h1>

                    <p
                      class="header__nav__hover-subitem"
                      v-for="(last, index) in second.list"
                      :key="index"
                    >
                      {{ last }}
                    </p>
                  </li>
                </ul>
              </div>
            </locale-router-link>
          </li>
        </ul>
      </nav>
    </div>
    <app-modal-city v-if="isShowModalCity" @close="isShowModalCity = false" />
  </header>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store";
import { actionTypes as actionTypesSearch } from "@/store/modules/search";

export default {
  name: "AppHeader",
  components: {
    LangChange: () => import("@/components/language/LangChange.vue"),
    AppModalCity: () => import("@/components/modal/AppModalCity.vue"),
  },
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: false,
      activeNavBar: 0,
      searchTitle: "",
      termSearch: "",
      searchEntity: null,
      headerSearch: false,
      isShowScrollBtn: true,
      isCorp: false,
      isShowModalCity: false,
      bottomNav: [
        {
          id: 1,
          name: "Винная карта",
          list: [
            {
              id: 1,
              name: "Страна",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
            {
              id: 2,
              name: "Вид",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
            {
              id: 3,
              name: "Сахар",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
            {
              id: 4,
              name: "Страна",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
            {
              id: 5,
              name: "Вид",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
            {
              id: 6,
              name: "Сахар",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
            {
              id: 7,
              name: "Страна",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
            {
              id: 8,
              name: "Вид",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
          ],
        },
        {
          id: 2,
          name: "Крепкий алкоголь",
          list: [
            {
              id: 1,
              name: "Страна",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
          ],
        },
        {
          id: 3,
          name: "Аперитивы",
          list: [
            {
              id: 1,
              name: "Страна",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
            {
              id: 2,
              name: "Вид",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
          ],
        },
        {
          id: 4,
          name: "Пиво и сидр",
          list: [
            {
              id: 1,
              name: "Страна",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
          ],
        },
        {
          id: 5,
          name: "Ликеры и настойки",
          list: [
            {
              id: 1,
              name: "Страна",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
            {
              id: 2,
              name: "Вид",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
            {
              id: 3,
              name: "Сахар",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
          ],
        },
        {
          id: 6,
          name: "Напитки и снеки",
          list: [
            {
              id: 1,
              name: "Страна",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
            {
              id: 2,
              name: "Вид",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
            {
              id: 3,
              name: "Сахар",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
          ],
        },
        {
          id: 7,
          name: "Табачная продукция",
          list: [
            {
              id: 1,
              name: "Страна",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
            {
              id: 2,
              name: "Вид",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
            {
              id: 3,
              name: "Сахар",
              list: ["Франция", "Италия", "РОССИЯ😤"],
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState(["isOpenMenu"]),
    ...mapState("cityModule", ["selectedCity"]),
    isShowHeader() {
      if (
        this.$router.app._route.fullPath === `/${this.$i18n.locale}/search` &&
        this.$isMobile()
      ) {
        return false;
      }
      return true;
    },
    isShowSearchTitle() {
      if (this.$router.app._route.fullPath === `/${this.$i18n.locale}/search`) {
        return true && this.$isMobile();
      }
      return false && this.$isMobile();
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        if (this.$route.path === `/${this.$i18n.locale}/corp`) {
          this.isCorp = true;
        } else {
          this.isCorp = false;
        }
        if (this.$route.path === `/${this.$i18n.locale}/contacts`) {
          this.isShowScrollBtn === false;
        } else {
          this.isShowScrollBtn === true;
        }
        this.showScrollBtn();
      },
    },
  },
  mounted() {
    document.querySelector(".default-wrapper").style = `padding-top: ${
      document.querySelector("header").offsetHeight
    }px`;
    if (this.$router === `/${this.$i18n.locale}/search`) {
      this.headerSearch = true;
    }

    this.showScrollBtn();

    this.addClassScrollBtn();

    if (!localStorage.getItem("selectedCity")) {
      this.isShowModalCity = true;
    }
  },
  methods: {
    ...mapActions({
      openMenu: actionTypes.changeIsOpenMenu,
      openSelectCity: actionTypes.changeIsOpenSelectCity,
      openIsAuthActive: actionTypes.changeIsAuthActive,
    }),
    ...mapActions("searchModule", {
      searchTerm: actionTypesSearch.getSearchTerm,
      changeIsSearchStart: actionTypesSearch.getIsSearchStart,
      entitySearch: actionTypesSearch.getSearchEntity,
    }),
    selectedSearch() {
      this.selected = !this.selected;
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },

    searchStart() {
      if (this.$route.path === `/${this.$i18n.locale}/search`) {
        this.searchTerm(this.termSearch);
        this.entitySearch(this.searchEntity);
        this.changeIsSearchStart(true);
      } else {
        this.$router.push(`/${this.$i18n.locale}/search`);
        this.searchTerm(this.termSearch);
        this.entitySearch(this.searchEntity);
        this.changeIsSearchStart(true);
      }
    },

    replacement(item) {
      this.searchTitle = item.label;
      this.selected = false;
      this.searchEntity = item.entity;
    },
    showScrollBtn() {
      if (this.$isMobile()) {
        switch (this.$router.app._route.fullPath) {
          case `/${this.$i18n.locale}/`:
            this.isShowScrollBtn = true;
            break;
          case `/${this.$i18n.locale}/catalog`:
            this.isShowScrollBtn = true;
            break;
          default:
            this.isShowScrollBtn = false;
            break;
        }
      }
    },
    addClassScrollBtn() {
      const header = document.querySelector(".header-wrapper");
      const body = document.querySelector("body");
      window.onscroll = function () {
        let scroll = window.pageYOffset || document.documentElement.scrollTop;
        if (scroll >= 150) {
          header?.classList?.add("is-fixed");
          body?.classList?.add("active");
        } else {
          header?.classList?.remove("is-fixed");
          body?.classList?.remove("active");
        }
        if (scroll >= 400) {
          document.querySelector(".scroll__top")?.classList.add("is-show");
        } else {
          document.querySelector(".scroll__top")?.classList.remove("is-show");
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.is-fixed {
  width: 100%;
  background: #fff;
  transition: 0.3s;
}
.header__nav__hover {
  position: absolute;
  // bottom: -100px;
  left: 0;
  z-index: 9999999999;
  background: #303132;
  overflow-x: auto;
  width: 100%;
  // height: 100%;
  height: auto;
  &-content {
    display: flex;
    margin: 30px 40px 40px;
  }
  &-item {
    margin-right: 20px;
    h1 {
      margin: 0 0 10px;
      font-size: 18px;
      line-height: 85.98%;
      font-family: "CeraPro Bold";
      color: #ffffff;
    }
  }
  &-subitem {
    font-size: 16px;
    margin-top: 16px;
    line-height: 85.98%;
    color: #ffffff;
    font-family: "CeraPro Regular";
  }
}
</style>
